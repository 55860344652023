<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Informe Costos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Administración</li>
                  <li class="breadcrumb-item active">Gestión Clientes</li>
                  <li class="breadcrumb-item active">Informe Costos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <label class="mb-0">Clientes</label>
                    <v-select
                      v-model="clientes"
                      class="form-control form-control-sm p-0"
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      placeholder="Clientes"
                      label="razon_social"
                      multiple
                      :options="listasForms.clientes"
                      :filterable="false"
                      @search="buscarClientes"
                      @input="getIndex()"
                    ></v-select>
                  </div>
                  <div class="col-md-1 pt-4">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-sm bg-gradient-navy"
                        v-if="clientes.length > 0"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync fa-spin"></i>
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-sm bg-gradient-primary"
                        v-if="
                          clientes.length > 0 &&
                            $store.getters.can(
                              'com.informeCostos.actualizarPromedioBrent'
                            )
                        "
                        @click="getIndex(1)"
                      >
                        <i class="fas fa-calculator"></i>
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 m-3" v-if="anticipos.length > 0">
                <table class="table table-sm table-bordered table-hover">
                  <thead class="bg-dark text-nowrap">
                    <tr>
                      <th>
                        <i class="fas fa-user-cog"></i>
                        Cliente
                      </th>
                      <th class="col-1">
                        <i class="fas fa-file-invoice-dollar"></i>
                        Cant. Anticipos
                      </th>
                      <th class="col-1">
                        <i class="fas fa-dollar-sign"></i>
                        Saldo + Anticipos
                      </th>
                      <th class="col-1">
                        <i class="fas fa-receipt"></i>
                        Cant. Guías
                      </th>
                      <th class="col-1">
                        <i class="fas fa-file-invoice"></i>
                        Total Guías
                      </th>
                      <th class="col-2">
                        <i class="fas fa-percent"></i>
                        Uso Anticipo
                      </th>
                    </tr>
                  </thead>
                  <tbody v-for="(ant, index) in anticipos" :key="index">
                    <tr data-widget="expandable-table" aria-expanded="false">
                      <td>{{ ant.razon_social }}</td>
                      <td class="text-center">
                        {{ ant.com_anticipos.length }}
                      </td>
                      <td class="text-right">{{ ant.total_anticipos }}</td>
                      <td class="text-center">{{ ant.guias.length }}</td>
                      <td class="text-right">{{ ant.total_guias }}</td>
                      <td class="col-1 text-center">
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped"
                            :class="{
                              'bg-danger': ant.porcentaje > 80,
                              'bg-warning':
                                ant.porcentaje > 60 && ant.porcentaje <= 80,
                              'bg-success': ant.porcentaje <= 60,
                            }"
                            :style="'width:' + ant.porcentaje + '%'"
                          >
                            <b>{{ ant.porcentaje }}%</b>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="expandable-body">
                      <td colspan="6">
                        <div :id="`accordion_${index}`">
                          <div
                            class="card card-light mb-2"
                            v-if="ant.com_anticipos.length > 0"
                          >
                            <div class="card-header">
                              <h4 class="card-title w-100">
                                <a
                                  class="d-block w-100 collapsed"
                                  data-toggle="collapse"
                                  :href="`#collapseAnticipos_${index}`"
                                  aria-expanded="false"
                                >
                                  <i class="fas fa-file-invoice-dollar"></i>
                                  <b> Anticipos</b>
                                </a>
                              </h4>
                            </div>
                            <div
                              :id="`collapseAnticipos_${index}`"
                              class="collapse"
                              :data-parent="`#accordion_${index}`"
                              style=""
                            >
                              <div class="card-body">
                                <table class="table table-sm table-bordered">
                                  <thead class="bg-dark text-nowrap">
                                    <tr>
                                      <th class="text-center">Fecha</th>
                                      <th class="text-center">Monto</th>
                                      <th class="col-1 text-center">
                                        Validado
                                      </th>
                                      <th class="col-1 text-center">
                                        Autorizado Por
                                      </th>
                                      <th class="col-1 text-center">
                                        Comprobante
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="antic in ant.com_anticipos"
                                      :key="antic.id"
                                    >
                                      <td>{{ antic.fecha }}</td>
                                      <td class="text-right">
                                        {{ antic.montos }}
                                      </td>
                                      <td class="text-center">
                                        <span
                                          :class="
                                            antic.validado == 1
                                              ? 'badge badge-success p-1 mt-2'
                                              : 'badge badge-danger p-1 mt-2'
                                          "
                                        >
                                          <i
                                            :class="
                                              antic.validado == 1
                                                ? 'fas fa-check-circle fa-lg text-white'
                                                : 'fas fa-times-circle fa-lg text-white'
                                            "
                                          ></i>
                                        </span>
                                      </td>
                                      <td>
                                        {{ antic.funcionario ? antic.funcionario.nombres + " " + antic.funcionario.apellidos : "" }}
                                      </td>
                                      <td class="text-center">
                                        <a
                                          target="_blank"
                                          class="btn btn-primary btn-sm"
                                          v-if="antic.link_documento"
                                          :href="
                                            uri_docs + antic.link_documento
                                          "
                                        >
                                          <i class="fa fa-download"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div
                            class="card card-navy mb-2"
                            v-if="ant.guias.length > 0"
                          >
                            <div class="card-header">
                              <h4 class="card-title w-100">
                                <a
                                  class="d-block w-100"
                                  data-toggle="collapse"
                                  :href="`#collapseGuia_${index}`"
                                >
                                  <i class="fas fa-receipt"></i>
                                  <b> Seguimiento Cargues</b>
                                </a>
                              </h4>
                            </div>
                            <div
                              :id="`collapseGuia_${index}`"
                              class="collapse"
                              :data-parent="`#accordion_${index}`"
                            >
                              <div class="card-body">
                                <div class="row w-100">
                                  <div
                                    class="col-sm-12 col-xs-12 table-responsive"
                                  >
                                    <div class="text-center m-2">
                                      <b>{{ nombreMes }}</b>
                                    </div>
                                    <div class="text-center m-2">
                                      <a
                                        class="ir-izquierda"
                                        style="color: #86bad8; cursor: pointer"
                                        @click="cambiarMes(-1)"
                                      >
                                        <span class="fa-stack fa-lg">
                                          <i
                                            class="fa fa-circle fa-stack-2x"
                                          ></i>
                                          <i
                                            class="fa fa-arrow-left fa-stack-1x fa-inverse"
                                          ></i>
                                        </span>
                                      </a>
                                      <a
                                        class="ir-derecha"
                                        style="color: #d3d3d3; cursor: pointer"
                                        @click="cambiarMes(1)"
                                      >
                                        <span class="fa-stack fa-lg">
                                          <i
                                            class="fa fa-circle fa-stack-2x"
                                          ></i>
                                          <i
                                            class="fa fa-arrow-right fa-stack-1x fa-inverse"
                                          ></i>
                                        </span>
                                      </a>
                                    </div>
                                    <table
                                      class="table table-sm table-bordered"
                                    >
                                      <thead class="bg-dark">
                                        <tr>
                                          <th rowspan="2" class="text-center">
                                            Fecha
                                          </th>
                                          <th rowspan="2" class="text-center">
                                            Asignación (Ctks)
                                          </th>
                                          <th rowspan="2" class="text-center">
                                            Producto
                                          </th>
                                          <th colspan="2" class="text-center">
                                            Entregas
                                          </th>
                                          <th colspan="3" class="text-center">
                                            Precio
                                          </th>
                                          <th rowspan="2" class="text-center">
                                            Retencion
                                          </th>
                                          <th colspan="1" class="text-center">
                                            Valor
                                          </th>
                                          <th
                                            rowspan="2"
                                            class="p-3"
                                            style="
                                              background-color: white;
                                              border: none;
                                            "
                                          ></th>
                                          <th rowspan="2" class="text-center">
                                            Saldo Inicial
                                          </th>
                                          <th rowspan="2" class="text-center">
                                            Anticipos
                                          </th>
                                          <th rowspan="2" class="text-center">
                                            Entregas
                                          </th>
                                          <th rowspan="2" class="text-center">
                                            Saldo Final
                                          </th>
                                        </tr>
                                        <tr>
                                          <th class="text-center">(Ctks)</th>
                                          <th class="text-center">
                                            Nsv (Bbls)
                                          </th>
                                          <th class="text-center">
                                            Brent (US$B)
                                          </th>
                                          <th class="text-center">
                                            Prima (US$B)
                                          </th>
                                          <th class="text-center">(US$)</th>
                                          <th class="text-center">(US$)</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <!-- <tr
                                          v-for="dia in diasDelMes"
                                          :key="dia"
                                        >
                                          <td>
                                            {{ obtenerFecha(año, mes, dia) }}
                                          </td>-->
                                        <tr
                                          v-for="guia in dataNuevos[0]"
                                          :key="guia.id"
                                          style="font-size: 10px"
                                        >
                                          <td class="text-center text-nowrap">
                                            {{ guia.descripcion_fecha }}
                                          </td>
                                          <td class="text-center">
                                            {{ guia.cant_aceptada }}
                                          </td>
                                          <td class="text-center">
                                            {{ guia.producto_liquido.nombre }}
                                          </td>
                                          <td class="text-center">
                                            {{ guia.cantGuias }}
                                          </td>
                                          <td class="text-center">
                                            {{ guia.tot_nsv }}
                                          </td>
                                          <td class="text-center">
                                            {{
                                              guia.brent != null
                                                ? parseFloat(
                                                    guia.brent
                                                  ).toFixed(2)
                                                : 0
                                            }}
                                          </td>
                                          <td class="text-center">
                                            {{
                                              parseFloat(guia.prima).toFixed(2)
                                            }}
                                          </td>
                                          <td class="text-center">
                                            {{
                                              (
                                                Math.round(
                                                  guia.primaImp * 1000
                                                ) / 1000
                                              ).toFixed(2)
                                            }}
                                          </td>
                                          <td class="text-center">
                                            {{ guia.retencion }}
                                          </td>
                                          <td class="text-center">
                                            {{
                                              guia.totalPrima
                                                ? guia.totalPrima.toFixed()
                                                : 0
                                            }}
                                          </td>
                                          <td style="border: none"></td>
                                          <td class="text-center">
                                            {{
                                              parseFloat(guia.saldoIni)
                                                ? guia.saldoIni.toFixed()
                                                : 0
                                            }}
                                          </td>
                                          <td class="text-center">
                                            {{
                                              guia.anticipo != null
                                                ? guia.anticipo.toFixed()
                                                : 0
                                            }}
                                          </td>
                                          <td class="text-center">
                                            {{
                                              guia.totalPrima
                                                ? guia.totalPrima.toFixed()
                                                : 0
                                            }}
                                          </td>
                                          <td class="text-center">
                                            {{
                                              parseFloat(guia.saldoFin)
                                                ? guia.saldoFin.toFixed()
                                                : 0
                                            }}
                                          </td>
                                        </tr>
                                        <!-- </tr> -->
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "InformeCostoIndex",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      clientes: [],
      filtros: {},
      anticipos: [],
      clint: this.$store.getters.getUser,
      listasForms: {
        clientes: [],
      },
      modalData: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      guiasAgrupadas: {},

      mes: 1,
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      anios: [2023, 2024, 2025, 2026], // Puedes ajustar según tus necesidades
      nMeses: [],
      mesActual: new Date().getMonth(),
      anioActual: new Date().getFullYear(),
      hoy: moment().format("YYYY-MM-DD"),
    };
  },

  created() {
    this.nMeses = this.anios.flatMap((anio) =>
      this.meses.map((mes) => ({ nombre: mes, anio }))
    );
    this.anios = [this.anioActual - 1, this.anioActual, this.anioActual + 1];
  },
  computed: {
    nombreMes() {
      return `${this.meses[this.mesActual]} ${this.anioActual}`;
    },

    dataNuevos() {
      return this.anticipos.map((antip) => {
        return antip.guias.filter((guia) => {
          const fechaExp =
            typeof guia.fecha_exp === "string"
              ? new Date(guia.fecha_exp + "T00:00:00")
              : guia.fecha_exp;

          if (fechaExp instanceof Date && !isNaN(fechaExp)) {
            return (
              fechaExp.getMonth() == this.mesActual &&
              fechaExp.getFullYear() == this.anioActual
            );
          }

          return false;
        });
      });
    },
  },

  methods: {
    getIndex(accion) {
      this.anticipos = [];

      if (this.clint.cliente && this.clint.cliente !== "") {
        this.clientes = [this.clint.cliente];
      }

      if (this.clientes.length > 0) {
        this.cargando = true;
        const me = this;
        this.filtros.cliente_id = this.clientes.map((cli) => cli.id);
        this.filtros.accion = accion;

        axios
          .get("/api/com/informeCostos", {
            params: this.filtros,
          })
          .then((response) => {
            me.cargando = false;
            me.anticipos = response.data;
            this.getData();
          })
          .catch(function (error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    cambiarMes(delta) {
      this.mesActual += delta;
      if (this.mesActual < 0) {
        this.mesActual = 11;
        this.anioActual--;
      } else if (this.mesActual > 11) {
        this.mesActual = 0;
        this.anioActual++;
      }
    },

    obtenerFecha(año, mes, dia) {
      return `${año}-${mes.toString().padStart(2, "0")}-${dia
        .toString()
        .padStart(2, "0")}`;
    },

    buscarClientes(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/com/clientes/lista?razon_social=" + search;
        axios
          .get(url, {
            params: { linea_negocio_id: 11 },
          })
          .then(function (response) {
            me.listasForms.clientes = response.data;
            loading(false);
          })
          .catch(function (error) {
            loading(false);
            me.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    getData() {
      this.anticipos.forEach((ant) => {
        let total_anticipos = 0;
        let total_guias = 0;
        let descripcion_fecha = null;

        let diasSemana = [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ];

        let saldo = ant.saldo;
        let montoAnti = 0;
        ant.com_anticipos.forEach((antic) => {
          antic.montos = this.formatCurrency("es-CO", "COP", 2, antic.monto);
          if (antic.validado == 1) {
            montoAnti += parseFloat(antic.monto);
          }
        });

        total_anticipos = parseFloat(montoAnti) + parseFloat(saldo);

        let saldoFin = 0;
        ant.guias.forEach((guia, indiceGuia) => {
          let cant_aceptada = 0;
          let prima = 0;
          let fechas_expedicion = new Date(guia.fecha_exp + "T00:00:00");
          let dia = fechas_expedicion.getDate();
          let nombreDia = diasSemana[fechas_expedicion.getDay()];
          let año = fechas_expedicion.getFullYear();
          let nombreMes = this.meses[fechas_expedicion.getMonth()];

          descripcion_fecha =
            nombreDia + ", " + nombreMes + " " + dia + ", " + año;
          guia.descripcion_fecha = descripcion_fecha;
          let anio = fechas_expedicion.getFullYear().toString();

          //Detalle oferta
          if (guia.detalle_oferta) {
            let cant_acep = 0;
            guia.detalle_oferta.forEach((detalle) => {
              if (detalle.cant_aceptada == null) {
                cant_acep = 0;
              } else {
                cant_acep = detalle.cant_aceptada;
              }
              cant_aceptada = parseFloat(cant_acep);
              if (detalle.historico_ofert) {
                prima = guia.precio_oferta;
              } else {
                prima = parseFloat(guia.precio_oferta);
              }
            });
          }

          guia.cant_aceptada = cant_aceptada;
          guia.prima = prima;

          if (ant.id == 22 || ant.id == 25 || ant.id == 8) {
            if (guia.fecha_exp > "2024-01-31") {
              guia.primaImp =
                parseFloat(guia.brent) +
                parseFloat(guia.prima) -
                (parseFloat(guia.brent) + parseFloat(guia.prima)) * 0.03;
              guia.retencion = "3%";
            } else {
              guia.primaImp =
                parseFloat(guia.brent) +
                parseFloat(guia.prima) -
                (parseFloat(guia.brent) + parseFloat(guia.prima)) * 0.054;
              guia.retencion = "5.4%";
            }
          } else if (ant.id == 14) {
            guia.primaImp = parseFloat(guia.brent) + parseFloat(guia.prima);

            guia.totalPrima =
              (parseFloat(guia.brent) -
                parseFloat(guia.prima) * parseFloat(guia.tot_nsv)) *
                parseFloat(guia.trm) +
              ((parseFloat(guia.brent) -
                parseFloat(guia.prima) * parseFloat(guia.tot_nsv)) *
                parseFloat(guia.trm) *
                0.19 -
                (parseFloat(guia.brent) -
                  parseFloat(guia.prima) * parseFloat(guia.tot_nsv)) *
                  parseFloat(guia.trm) *
                  0.025);
          } else {
            guia.primaImp = parseFloat(guia.brent) + parseFloat(guia.prima);
          }

          guia.totalPrima =
            parseFloat(guia.primaImp) * parseFloat(guia.tot_nsv);

          total_guias += parseFloat(guia.totalPrima);
          let monto = 0;
          if (indiceGuia == 0) {
            guia.saldoIni = parseFloat(ant.saldo);
            guia.saldoFin =
              parseFloat(ant.saldo) - parseFloat(guia.totalPrima.toFixed());

            ant.com_anticipos.forEach((antic) => {
              if (antic.validado == 1) {
                if (antic.fecha < guia.fecha_exp) {
                  guia.saldoIni += parseFloat(antic.monto);
                  guia.saldoFin += parseFloat(antic.monto);
                } else if (antic.fecha == guia.fecha_exp) {
                  guia.saldoFin += parseFloat(antic.monto);
                  monto += parseFloat(antic.monto);
                } else {
                  guia.saldoFin =
                    parseFloat(guia.saldoIni) -
                    parseFloat(guia.totalPrima.toFixed());
                }
              }
            });
            guia.anticipo = monto;
            guia.saldoFin = parseFloat(guia.saldoFin) + parseFloat(monto);
            saldoFin = parseFloat(guia.saldoFin);
          } else {
            guia.saldoIni = parseFloat(saldoFin);
            guia.saldoFin =
              parseFloat(saldoFin) - parseFloat(guia.totalPrima.toFixed());

            ant.com_anticipos.forEach((antic) => {
              if (antic.validado == 1) {
                if (antic.fecha == guia.fecha_exp) {
                  monto += parseFloat(antic.monto);
                } else {
                  guia.saldoFin =
                    parseFloat(guia.saldoIni) - parseFloat(guia.totalPrima);

                  if (
                    antic.fecha < ant.guias[indiceGuia].fecha_exp &&
                    antic.fecha > ant.guias[indiceGuia - 1].fecha_exp
                  ) {
                    monto += parseFloat(antic.monto);
                  }
                }
              }
            });

            if (
              ant.guias[indiceGuia].fecha_exp !=
              ant.guias[indiceGuia - 1].fecha_exp
            ) {
              guia.anticipo = monto;
            } else {
              guia.anticipo = 0;
            }
            guia.saldoFin =
              parseFloat(guia.saldoFin) + parseFloat(guia.anticipo);
            saldoFin = parseFloat(guia.saldoFin);
          }

          if (!this.guiasAgrupadas[anio]) {
            this.guiasAgrupadas[anio] = {};
          }

          if (!this.guiasAgrupadas[anio][nombreMes]) {
            this.guiasAgrupadas[anio][nombreMes] = [];
          }

          this.guiasAgrupadas[anio][nombreMes].push(guia);
        });

        ant.total_guias = total_guias;
        ant.total_anticipos = total_anticipos;
        ant.porcentaje = parseInt(
          (parseFloat(total_guias) * 100) / parseFloat(total_anticipos)
        );

        ant.total_anticipos = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          ant.total_anticipos
        );

        ant.total_guias = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          ant.total_guias
        );
      });
    },

    getModalData(data) {
      this.modalData = {
        title: null,
        data: null,
      };
      this.modalData.title = data.razon_social;
      this.modalData.data = data;
    },
  },
};
</script>
